<template>
    <div class="control">
        <div class="control-top">
            <div class="person-box flex">
                <van-image
                    round
                    width="65px"
                    height="65px"
                    :src="pageData.headimgurl"
                />
                <div style="margin-left: 15px">
                    <div class="flex person-name">
                        <van-skeleton :loading="loading" title-width="50" title>
                            <span>{{ detail.name }}</span>
                        </van-skeleton>

                        <img src="https://keyang2.tuzuu.com/uuo/ydj/55cbf4cb4688f297f6a75708bf575151.png"/>
                    </div>
                    <div class="person-tel">
                        <van-skeleton :loading="loading" title-width="100" title>
                            <span>{{ detail.tel }}</span>
                        </van-skeleton>
                    </div>
                </div>
            </div>
            <div class="interests-box">
                <img src="https://keyang2.tuzuu.com/uuo/ydj/6512cf503a0ab12c13aa72194d181324.png" class="interests-bg"/>
                <div class="interests-content">
                    <div class="interests-title">特效权益卡</div>
                    <div class="flex-between">
                        <div class="flex">
                            <div class="interests-item flex-align">
                                <img src="https://keyang2.tuzuu.com/uuo/ydj/df706c9a2ff7bbce18bcd4bbfc3177e6.png"/>
                                7大权益
                            </div>
                            <div class="interests-item flex-align">
                                <img src="https://keyang2.tuzuu.com/uuo/ydj/085a6d7f03d30d55bd6583fd266787ce.png"/>
                                100种特药
                            </div>
                        </div>
                        <div class="interests-time">
                            <van-skeleton :loading="loading" title-width="80" title>
                                {{ detail.can_use_end | setTime }}到期
                            </van-skeleton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="control-bottom">
            <router-link class="control-bottom-item flex-between" to="/ydj/person-interests">
                <div>
                    <div class="item_name">我的权益</div>
                    <div class="item_info">7项尊享权益</div>
                </div>
                <van-icon name="arrow" color="#707070" size="18"/>
            </router-link>
            <router-link class="control-bottom-item flex-between" to="/ydj/person-order">
                <div>
                    <div class="item_name">我的预约</div>
                    <div class="item_info">专属客服</div>
                </div>
                <van-icon name="arrow" color="#707070" size="18"/>
            </router-link>
        </div>
    </div>
</template>

<script>
import {formatDate} from "@/utils/util";
import wx from "weixin-js-sdk";
import wxShare from "@/wechat/weChatTile";

export default {
    data() {
        return {
            loading: true,
            pageData: {},
            detail: {}
        }
    },
    created() {
        axios.get('/user_center').then(res => {
            this.pageData = res.data
        })
        axios.get("/ydj/get_my_power_card").then(res => {
            if (res.code === 1) {
                if (res.data.length === 0) {
                    this.$router.push("/ydj")
                } else {
                    this.detail = res.data[0] || {}
                    this.loading = false
                }
            }
        })
    },
    mounted() {
        wxShare({
            title: '一站式健康管理服务平台',
            desc:'康来家园，中国健康管理领导者',
            link:window.location.origin + '/ydj',
        })
    },
    filters: {
        setTime(value) {
            return formatDate(value, 'YYYY-MM-DD')
        }
    }
}
</script>

<style scoped lang="less">
.control {
    width: 100%;
    min-height: 100vh;
    background-image: url("https://keyang2.tuzuu.com/uuo/ydj/aa5760585ab3009c65d859989e0ba503.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0% -30%;
    display: flex;
    flex-direction: column;

    .control-top {
        padding: 15vh 23px 0;

        .person-box {
            margin-bottom: 16px;

            .person-name {
                font-size: 18px;
                font-weight: bold;
                color: #FFFFFF;

                img {
                    width: 21px;
                    margin-left: 10px;
                }
            }

            .person-tel {
                font-size: 15px;
                font-weight: 500;
                color: #E2E2E2;
                margin-top: 8px;
            }
        }

        .interests-box {
            position: relative;

            .interests-bg {
                width: 100%;
                display: block;
            }

            .interests-content {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 12px 0 20px;
                box-sizing: border-box;

                .interests-title {
                    font-size: 16px;
                    font-weight: bold;
                    color: #FFE2B3;
                    line-height: 23px;
                    margin-bottom: 10px;
                }

                .interests-item {
                    font-size: 10px;
                    font-weight: 500;
                    color: #D4D4D4;

                    img {
                        width: 9px;
                        margin-right: 5px;
                    }

                    & + .interests-item {
                        margin-left: 34px;
                    }
                }

                .interests-time {
                    font-size: 10px;
                    font-weight: 500;
                    color: #D4D4D4;
                }
            }

            &:before {
                content: '';
                position: absolute;
                bottom: -6px;
                left: 0;
                width: 11px;
                height: 6px;
                right: 0;
                margin: auto;
                background: #3F4550;
                z-index: 2;
                clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
            }
        }
    }

    .control-bottom {
        flex: 1;
        background: #FFFFFF;
        border-radius: 12px 12px 0px 0px;
        padding: 16px 21px;

        .control-bottom-item {
            padding: 23px 9px 27px 16px;
            background-color: rgba(240, 255, 251, 0.73);
            background-image: url("https://keyang2.tuzuu.com/uuo/ydj/7ba7e9c60809c58b5cc2a2de85259e91.png");
            background-repeat: no-repeat;
            background-size: auto 80%;
            background-position: 90% 100%;

            .item_name {
                font-size: 14px;
                font-weight: bold;
                color: #232323;
                line-height: 20px;
                margin-bottom: 6px;
            }

            .item_info {
                font-size: 12px;
                font-weight: 500;
                color: #58B889;
                line-height: 17px;
            }

            & + .control-bottom-item {
                margin-top: 12px;
            }

            &:nth-of-type(2) {
                background-image: url("https://keyang2.tuzuu.com/uuo/ydj/5f6505c97a3c83fc5228ebca67d052c4.png");
                background-color: rgba(248, 249, 255, 0.73);

                .item_info {
                    color: #7172D0;
                }
            }
        }
    }

    :deep(.van-skeleton) {
        padding: 0;
    }
}
</style>